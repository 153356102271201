/* istanbul ignore file */
import React from 'react';

export const LazyBookingGauges = React.lazy(
  () => import('./../pages/vam-easy-gauge/BookingGauges')
);

export const LazyReplacementBooking = React.lazy(
  () => import('./../pages/vam-easy-gauge/ReplacementBooking')
);

export const LazySetModel = React.lazy(() => import('./../pages/vam-easy-gauge/SetModel'));

export const LazySetCreation = React.lazy(() => import('./../pages/vam-easy-gauge/SetCreation'));

export const LazyLicenseeListing = React.lazy(() => import('./../pages/licensee/LicenseeListing'));

export const LazyLicenseeCreation = React.lazy(() => import('./../pages/licensee/CreateLicensee'));

export const LazyLicenseeUpdate = React.lazy(() => import('./../pages/licensee/UpdateLicensee'));

export const LazyUserCreation = React.lazy(() => import('./../pages/user/CreateUser'));

export const LazyUserListing = React.lazy(() => import('./../pages/user/UserListing'));

export const LazyInvoiceListing = React.lazy(() => import('./../pages/invoicing/InvoiceListing'));

export const LazyCaseListing = React.lazy(() => import('./../pages/case/CaseListing'));

export const LazyGaugeDepotListing = React.lazy(
  () => import('./../pages/gauge-depot/GaugeDepotListing')
);

export const LazyManageCompanies = React.lazy(() => import('./../pages/companies/ManageCompanies'));

export const LazyManageUsers = React.lazy(() => import('./../pages/user/ManageUsers'));

export const LazyMyUsersListing = React.lazy(() => import('./../pages/user/LicenseeUsersListing'));

export const LazyCreatePublication = React.lazy(
  () => import('./../pages/documentation/CreatePublication')
);

export const LazyManageDocumentation = React.lazy(
  () => import('./../pages/documentation/ManageDocumentation')
);
