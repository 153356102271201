import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Checkbox } from '@mui/material';
import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { ProQualification } from '../../interfaces/context';
import { InvoiceGrid } from '../../interfaces/invoice';
import { LabelValue } from '../../interfaces/label-value';
import { SetGauge } from '../../interfaces/set-creation';
import { VegDispatchApi, VegReturnApi } from '../../interfaces/shipping';
import { LicenseeGauge, ManageBookingGrid, VegBookingStockParams } from '../../interfaces/veg';
import { $darkRed, $primaryBlack } from '../../styles/colors';
import {
  BidentConnection,
  BookingStatus,
  Currency,
  DispatchBy,
  RentalType,
  ShippingSource,
  VegLocation,
} from '../../utils';
import {
  arrayToLabelValues,
  formatDate,
  getDateFromString,
  getDesignationProductWithSetId,
  gettingWeightThickness,
  removeSpecChars,
  stringDateComparator,
} from '../../utils/functions';
import { columnRenderHeader } from '../data-grid/utils/functions';
import { FormSwitchField, RentalTypeNode } from '../shared';
import { FormSchemaType as DispatchFormSchemaType } from './dispatch-summary/DispatchForm';
import { FormSchemaType as ReturnFormSchemaType } from './make-return/MakeReturnForm';

const gridCellMethodClass = 'grid-cell-method';

export const gaugeStockLocationCols: GridColDef[] = [
  {
    field: 'selected',
    headerName: '',
    renderCell: /* istanbul ignore next */ ({ value }) => <Checkbox checked={value} />,
    disableColumnMenu: true,
    sortable: false,
  },
  { field: 'town', headerName: 'LOCATION', flex: 1 },
  { field: 'quantity', headerName: 'QUANTITY', flex: 1 },
  {
    field: 'next_available_date',
    headerName: 'ESTIMATED AVAILABLE DATE',
    flex: 1,
    renderCell: /* istanbul ignore next */ ({ value }) => value || '-',
  },
];

export function canShowBidentBox(connection: string): boolean {
  return BidentConnection.includes(removeSpecChars(connection));
}

export function AddBidentPrice(bidentInfoText: string, price: number): ReactNode {
  return (
    <Typo fontWeight={400} color={$primaryBlack} variant="caption">
      <Trans values={{ price }} components={{ bold: <strong /> }}>
        {bidentInfoText}
      </Trans>
    </Typo>
  );
}

export function getBookingStatusLabelValue(): LabelValue[] {
  return arrayToLabelValues(
    Object.values(BookingStatus),
    'manageBookingDetail.changeStatusDialog.status.'
  );
}

export function applyRulesToTargetStatus(
  currentStatus: string,
  rentalType: RentalType,
  isSetRelated: boolean
): LabelValue[] {
  const bookingStatusOptions = getBookingStatusLabelValue();

  if (currentStatus === BookingStatus.Undefined || currentStatus === BookingStatus.Canceled) {
    return bookingStatusOptions;
  }
  const applyNewOrderWithoutSetLinked = [
    'available_in_other_site',
    'manufacturing_gauge',
    'wait_for_information',
    'not_available',
    'canceled',
  ];
  const gpsUnderPreparation = 'gps_under_preparation';

  const applyUnderPreparation = ['waiting_for_collection', 'shipment_ongoing', 'canceled'];

  switch (currentStatus) {
    case BookingStatus.NewOrder:
      if (isSetRelated) {
        return bookingStatusOptions.filter((status) => status.value === 'under_preparation');
      } else {
        if (rentalType === RentalType.Long) {
          return bookingStatusOptions.filter((status) =>
            applyNewOrderWithoutSetLinked.includes(status.value)
          );
        } else {
          return bookingStatusOptions.filter((status) =>
            [...applyNewOrderWithoutSetLinked, gpsUnderPreparation].includes(status.value)
          );
        }
      }
    case BookingStatus.UnderPreparation:
      return bookingStatusOptions.filter(({ value }) => applyUnderPreparation.includes(value));
    case BookingStatus.GpsUnderPreparation:
      return bookingStatusOptions.filter(
        ({ value }) => value === BookingStatus.Gps || value === BookingStatus.Canceled
      );
    case BookingStatus.AvailableInOtherSite:
    case BookingStatus.ManufacturingGauge:
    case BookingStatus.WaitForInformation:
    case BookingStatus.NotAvailable:
      return bookingStatusOptions.filter(
        ({ value }) => value === BookingStatus.UnderPreparation || value === BookingStatus.Canceled
      );

    case BookingStatus.WaitingForCollection:
    case BookingStatus.ShipmentOngoing:
      return bookingStatusOptions.filter(
        ({ value }) => value === BookingStatus.Delivered || value === BookingStatus.Canceled
      );

    case BookingStatus.Delivered:
      return bookingStatusOptions.filter(
        ({ value }) => value === BookingStatus.ReturnOngoing || value === BookingStatus.Canceled
      );
    case BookingStatus.ReturnOngoing:
      return bookingStatusOptions.filter(
        ({ value }) => value === BookingStatus.ReturnReceived || value === BookingStatus.Canceled
      );
    case BookingStatus.Terminated:
      return [];
    case BookingStatus.ReturnReceived:
    case BookingStatus.Gps:
      return bookingStatusOptions.filter(
        ({ value }) => value === BookingStatus.Canceled || value === BookingStatus.Terminated
      );
    case BookingStatus.ReplacementRequested:
    case BookingStatus.ReplacementDone:
      return [];
  }
}

export function applyShowBidentPrice(
  setState: Dispatch<SetStateAction<boolean>>,
  setValue: UseFormSetValue<FieldValues>,
  connection: string
) {
  setValue('bident', false);
  if (canShowBidentBox(connection)) {
    setState(true);
  } else {
    setState(false);
  }
}

export function checkSearchSetsParams(formValues: Record<string, any>): boolean {
  const { connection, od_inch, weight_thickness, end, application, option, start_date, end_date } =
    formValues;
  return Boolean(
    connection &&
      od_inch &&
      weight_thickness &&
      end &&
      application &&
      option &&
      start_date &&
      end_date
  );
}

export function mappingStockAvailable(formValues: Record<string, any>): VegBookingStockParams {
  const { connection, od_inch, weight_thickness, end, application, option, start_date, end_date } =
    formValues;
  const weightThickness = gettingWeightThickness(weight_thickness);

  return {
    connection,
    od_inch,
    weight: weightThickness.weight,
    thickness: weightThickness.thickness,
    end,
    application,
    option,
    booking_end_date: end_date,
    booking_start_date: start_date,
  };
}

export function getSelectedGaugeLocation(rowId: number, selectedLocation: number): boolean {
  if (rowId === selectedLocation) {
    return true;
  }
  return rowId === +VegLocation.AulnoyeAymeries && !selectedLocation;
}

export const manageBookingColumns: GridColDef[] = [
  {
    field: 'method',
    headerName: 'METHOD',
    cellClassName: gridCellMethodClass,
    maxWidth: 80,
    flex: 1,
  },
  {
    field: 'business_number',
    headerName: 'BUSINESS',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'booking_id',
    headerName: 'ID #',
    flex: 0.5,
  },
  {
    field: 'licensee_number',
    headerName: 'LICENSEE',
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'licensee_name',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('LICENSEE', 'NAME'),
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'rental_type',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('RENTAL', 'TYPE'),
    minWidth: 80,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, RentalType>) => RentalTypeNode(params.value),
  },
  {
    field: 'connection',
    headerName: 'CONNECTION',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'od_txt',
    headerName: 'OD',
    maxWidth: 80,
    flex: 1,
  },
  {
    field: 'weight_thickness',
    minWidth: 120,
    flex: 1,
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('WEIGHT /', 'THICKNESS'),
  },
  {
    field: 'end',
    headerName: 'END',
    headerAlign: 'center',
    align: 'center',
    maxWidth: 80,
    flex: 1,
  },
  {
    field: 'application',
    headerName: 'APPLICATION',
    width: 120,
    flex: 1,
  },
  {
    field: 'creation_date',
    minWidth: 120,
    flex: 1,
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('REQUEST', 'DATE'),
  },
  {
    field: 'modification_date',
    minWidth: 120,
    flex: 1,
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('LAST', 'MODIFICATION'),
  },
  {
    field: 'start_date',
    minWidth: 120,
    flex: 1,
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('START', 'DATE'),
  },
  {
    field: 'end_date',
    headerName: 'END DATE',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'stock',
    headerName: 'STOCK',
    cellClassName: (params: GridCellParams<any, number>) => {
      if (params.value == null) {
        return '';
      }
      return clsx('grid-cell-stock', {
        red: params.value === 0,
        black: params.value > 0,
      });
    },
    maxWidth: 80,
  },
];

export const renderConsigneeToggle = (
  label: string,
  checked: boolean,
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
) => (
  <div className="tw-my-4">
    <FormSwitchField
      name="isConsigneeAddress"
      label={label}
      checked={checked}
      onChange={onChange}
      data-testid="switch-consignee-address-testid"
    />
  </div>
);

export const dispatchSummaryColumns: GridColDef[] = [
  {
    field: 'business_number',
    headerName: 'BUSINESS #',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'rental_type',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('RENTAL', 'TYPE'),
    minWidth: 80,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, RentalType>) =>
      RentalTypeNode(params.value),
  },
  {
    field: 'address_name',
    headerName: 'ADDRESS',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'creation_date',
    minWidth: 120,
    flex: 1,
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('RESERVATION', 'DATE'),
  },
  {
    field: 'start_date',
    minWidth: 120,
    flex: 1,
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('START', 'DATE'),
  },
  {
    field: 'designation',
    headerName: 'DESIGNATION',
    minWidth: 300,
    flex: 1,
    renderCell: /* istanbul ignore next */ (params: GridRenderCellParams<any, RentalType>) =>
      getDesignationProductWithSetId(params.row.set_id, {
        connection: params.row.connection,
        application: params.row.application,
        end: params.row.end,
        od_txt: params.row.od_txt,
        weight_thickness: params.row.weight_thickness,
      }),
  },
];

export const getLicenseeNumberByGridRowId = (
  objectArray: ManageBookingGrid[] | InvoiceGrid[],
  rowId: number
): number => {
  return objectArray[rowId]?.licensee_number;
};

export function caseSortComparator(v1: string | number, v2: string | number): number {
  // If both values are numbers, sort numerically
  if (!isNaN(Number(v1)) && !isNaN(Number(v2))) {
    return Number(v1) - Number(v2);
  }
  // If both are '-', they are equal
  if (v1 === '-' && v2 === '-') return 0;
  // If v1 is '-', treat it as smaller
  if (v1 === '-') return -1;
  // If v2 is '-', treat it as smaller
  if (v2 === '-') return 1;
  // For other cases, convert to numbers if possible and compare
  return String(v1).localeCompare(String(v2));
}

export const myGaugesColumns: GridColDef[] = [
  {
    field: 'case',
    headerName: 'CASE',
    cellClassName: gridCellMethodClass,
    flex: 1,
    sortComparator: caseSortComparator,
  },
  {
    field: 'gauge_name',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('GAUGE', 'DESIGNATION'),
    minWidth: 250,
    flex: 1,
  },
  {
    field: 'business_number',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('BUSINESS', 'NUMBER'),
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'purchase_order',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('PURCHASE', 'ORDER'),
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'purchase_date',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('PURCHASE', 'DATE'),
    minWidth: 80,
    flex: 1,
    sortComparator: stringDateComparator,
  },
  {
    field: 'modification_date',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('LAST', 'MODIFICATION'),
    minWidth: 80,
    flex: 1,
    sortComparator: stringDateComparator,
    renderCell: /* istanbul ignore next */ ({ value }) => (value ? formatDate(value, false) : '-'),
  },
  {
    field: 'rental_type',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('RENTAL', 'TYPE'),
    minWidth: 80,
    renderCell: (params: GridRenderCellParams<any, RentalType>) => RentalTypeNode(params.value),
  },
  {
    field: 'duration',
    headerName: 'DURATION',
    minWidth: 80,
  },
  {
    field: 'next_set_calibration_date',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('NEXT', 'CALIBRATION'),
    minWidth: 80,
    flex: 1,
    sortComparator: stringDateComparator,
    renderCell: /* istanbul ignore next */ (params) => (
      <Typo color={params.row.grid_status === 'KO' ? $darkRed : 'none'}>{params.value}</Typo>
    ),
  },
];

export const mappingMyGauges = (booking: LicenseeGauge) => {
  const nextSetCalibrationDate = booking.next_set_calibration_date
    ? getDateFromString(booking.next_set_calibration_date)
    : null;
  const today = new Date();
  const isWithinOrPastTwoWeeks =
    // 0 to 13 equals 14 days
    // differenceInDays < 0 means the date is in the past
    nextSetCalibrationDate &&
    (differenceInDays(nextSetCalibrationDate, today) <= 13 ||
      differenceInDays(today, nextSetCalibrationDate) >= 0);
  return {
    ...booking,
    id: booking.booking_id,
    purchase_date: formatDate(booking.purchase_date, false),
    modification_date: formatDate(booking.modification_date, false),
    next_set_calibration_date: booking.next_set_calibration_date
      ? formatDate(booking.next_set_calibration_date, false)
      : '-',
    case: booking.case || '-',
    duration: booking.duration !== null ? booking.duration : '-',
    gauge_name: booking.gauge_name || '-',
    grid_status: isWithinOrPastTwoWeeks ? 'KO' : '',
  };
};

export const mapDispatchFormValues = (
  data: DispatchFormSchemaType,
  bookings: ManageBookingGrid[],
  licenseeNumber: number,
  userId: number
): VegDispatchApi => {
  return {
    account_number: data.account_number,
    air_way_bill: data.air_way_bill,
    box_count: data.nb_boxes,
    comments: data.comments,
    currency: data.currency as Currency,
    incoterms: data.incoterms,
    licensee_number: licenseeNumber,
    shipping_mode: data.dispatch_by as DispatchBy,
    shipping_source: ShippingSource.Dispatch,
    user_id: userId,
    sets: data.sets?.map((set) => ({
      booking_id: bookings.find((booking) => booking.set_id === set.set_id)?.booking_id,
      business_number: set.business_number,
      proforma_value: set.proforma_value,
      set_id: set.set_id,
    })),
  };
};

export const getProformaValueKey = (isReturn: boolean, idx: number) =>
  isReturn ? `sets.${idx}.proforma_value` : `set_${idx}.proforma_value`;

export const mapReturnFormValues = (
  data: ReturnFormSchemaType,
  bookings: ManageBookingGrid[],
  licenseeNumber: number,
  userId: number
): VegReturnApi => {
  return {
    account_number: data.account_number,
    air_way_bill: data.air_way_bill,
    box_count: data.nb_boxes,
    comments: data.comments,
    currency: data.currency as Currency,
    incoterms: data.incoterms,
    licensee_number: licenseeNumber,
    shipping_mode: data.dispatch_by as DispatchBy,
    shipping_source: ShippingSource.Return,
    user_id: userId,
    sets: data.sets?.map((set) => ({
      booking_id: bookings.find((booking) => booking.set_id === set.set_id)?.booking_id,
      business_number: set.business_number,
      proforma_value: set.proforma_value,
      set_id: set.set_id,
      comments: set.comments,
      gauges: set.gauges as SetGauge[],
    })),
  };
};

export const getLicenseeConnectionEnds = (product?: ProQualification): LabelValue[] =>
  product
    ? product.end
        .replace(' ', '')
        .split('&')
        ?.map((end) => ({ label: end.trim(), value: end.trim() }))
    : [];

export const isLocationDialogButtonDisabled = ({
  fetchAvailableStock,
  isReplacementLtr,
  isSetRelated,
  status,
}: {
  fetchAvailableStock: boolean; // The stock has been fetched, so there are values in the dialog
  isReplacementLtr: boolean; // The booking is a replacement
  isSetRelated: boolean; // The booking has a set linked to it
  status: BookingStatus; // The booking status
}): boolean => {
  // Dialog is enabled only if the booking status is allowed
  const allowedStatuses = [BookingStatus.AvailableInOtherSite];

  return !(
    allowedStatuses.includes(status) &&
    fetchAvailableStock &&
    !isReplacementLtr &&
    !isSetRelated
  );
};
