import { $grey40 } from '@digital-at-vallourec/steel-design-system-react/dist/styles/colors';

import { $darkGreen } from '../../styles/colors';

export enum JobStatus {
  OPEN = 'OPEN',
  COMPLETE = 'COMPLETE',
}

export const JOB_STATUS_COLORS = {
  OPEN: $grey40,
  COMPLETE: $darkGreen,
};
