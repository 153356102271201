// istanbul ignore file

export const documentationEndpoints = {
  distinct_products_applicability: '/distinct-products-applicability',
  distinct_grades_applicability: '/distinct-grades-applicability',
  create_publication: '/create-publication',
  save_files: '/save-files',
  documents: '/documents',
  download_file: '/download-file',
};
