import { FieldOperator } from '../../components/documentation/ApplicabilityFilter/utils';
import { ApplicabilityValueElement } from '../../interfaces/documentation';

export function getJobTitle(): string {
  const date = new Date(); // Get the current date
  const year = date.getFullYear(); // Get the current year

  // Get the current month (from 0 to 11)
  const month = date.getMonth();

  // Determine the quarter (Q1: January-March, Q2: April-June, Q3: July-September, Q4: October-December)
  let quarter: string;
  if (month >= 0 && month <= 2) {
    quarter = 'Q1';
  } else if (month >= 3 && month <= 5) {
    quarter = 'Q2';
  } else if (month >= 6 && month <= 8) {
    quarter = 'Q3';
  } else {
    quarter = 'Q4';
  }

  // Generate the title
  return `Create a Job - ${quarter} ${year}`;
}

export enum JobType {
  NEW_PART = 'NEW PART',
  REPAIR = 'REPAIR',
}

// Define the TypeScript type for each part type
export interface PartType {
  type: string;
  nbSides: number;
  nbPin: number | null;
  nbBox: number | null;
}

// Create an array of part types based on the simplified data
export const partTypes: PartType[] = [
  {
    type: 'Lifting / Handling equipment',
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: 'Waterbushing / Circulating head',
    nbSides: 1,
    nbPin: 1,
    nbBox: 0,
  },
  {
    type: 'Test cap / plug',
    nbSides: 1,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Crossover',
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Pup joint',
    nbSides: 1,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Nipple',
    nbSides: 1,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: 'Coupling',
    nbSides: 1,
    nbPin: 0,
    nbBox: 2,
  },
  {
    type: 'Float shoe',
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: 'Float collar',
    nbSides: 2,
    nbPin: 1,
    nbBox: 1,
  },
  {
    type: 'Casing / Tubing hanger',
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: 'Flow coupling / Blast joint',
    nbSides: 2,
    nbPin: 1,
    nbBox: 1,
  },
  {
    type: '3-way crossover / Y tool',
    nbSides: 3,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Bull plug / Mule shoe / Re-entry guide',
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: 'Landing nipple / No-go locator',
    nbSides: 1,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: 'Safety valve',
    nbSides: 2,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: 'Eccentric completion equipment',
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Packer',
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Liner hanger',
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: 'Full-length / Base pipe',
    nbSides: 1,
    nbPin: null,
    nbBox: null,
  },
  {
    type: 'Other',
    nbSides: 1,
    nbPin: null,
    nbBox: null,
  },
];

export const handleFilterChange = (
  filters: ApplicabilityValueElement[],
  setFilters: React.SetStateAction<any>,
  controlValue: any,
  field: string
) => {
  // Check if the filter already exists
  const existingFilterIndex = filters.findIndex((filter) => filter.field === field);

  // If the selected value is empty, remove the existing filter if it exists
  if (controlValue === '' || controlValue === null || controlValue === undefined) {
    if (existingFilterIndex !== -1) {
      const updatedFilters = [...filters];
      updatedFilters.splice(existingFilterIndex, 1); // Remove the filter
      setFilters(updatedFilters);
    } else {
      setFilters(filters); // No changes needed if the filter doesn't exist
    }
    return;
  }

  // If the filter exists, update its value
  if (existingFilterIndex !== -1) {
    const updatedFilters = [...filters];
    updatedFilters[existingFilterIndex].value = controlValue;
    setFilters(updatedFilters);
  } else {
    // If the filter does not exist, add it to the filters array
    setFilters([...filters, { field, operator: FieldOperator.EQUAL, value: controlValue }]);
  }
};
